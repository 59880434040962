<form [formGroup]="addressLookupForm" data-testid="shipping-address-lookup">
  <div [class]="containerClasses">
    <lib-custom-autocomplete-form-field
      class="w-full"
      [label]="
        addressLookupFields?.location_label ??
        addressLookupFields?.location_placeholder ??
        (['addressLookup', 'post_code'] | translate)
      "
      [labelSuffix]="get('city')"
      [selected]="!!get('city')"
      name="postCode"
      [icon]="addressLookupFields?.location_icon ?? 'round-globe'"
      [placeholder]="
        addressLookupFields?.location_placeholder ?? (['addressLookup', 'post_code_placeholder'] | translate)
      "
      [size]="4"
      [options]="cityOptions"
      formControlName="postCode"
      (optionSelected)="selectCity($event.label)"
      (onBlur)="_onBlur('postCode', postCode)"
    >
    </lib-custom-autocomplete-form-field>

    <lib-custom-autocomplete-form-field
      class="w-full"
      [label]="
        addressLookupFields?.street_label ??
        addressLookupFields?.street_placeholder ??
        (['addressLookup', 'street'] | translate)
      "
      name="street"
      [icon]="addressLookupFields?.street_icon ?? 'swiss-flag'"
      [placeholder]="addressLookupFields?.street_placeholder ?? (['addressLookup', 'street_placeholder'] | translate)"
      [options]="streetOptions"
      [selected]="streetSelected"
      formControlName="street"
      (optionSelected)="selectStreet($event.label)"
      (opened)="streetPanelOpened()"
      (closed)="streetPanelClosed()"
      (onBlur)="_onBlur('street', street)"
      data-testid="new-street"
    >
    </lib-custom-autocomplete-form-field>

    <lib-custom-autocomplete-form-field
      class="w-full"
      id="streetNumber"
      [label]="
        addressLookupFields?.street_number_label ??
        addressLookupFields?.street_number_placeholder ??
        (['addressLookup', 'number'] | translate)
      "
      name="streetNumber"
      [icon]="addressLookupFields?.street_number_icon ?? 'analog-mail'"
      [placeholder]="addressLookupFields?.street_number_placeholder ?? (['addressLookup', 'number'] | translate)"
      [options]="streetNumbersOptions"
      [selected]="streetNumberSelected"
      formControlName="streetNumber"
      (optionSelected)="selectStreetNumber($event.label)"
      (opened)="streetNumberPanelOpened()"
      (closed)="streetNumberPanelClosed()"
      (onBlur)="_onBlur('streetNumber', streetNumber)"
      data-testid="new-street-number"
    >
    </lib-custom-autocomplete-form-field>
  </div>
</form>
