import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type Theme = 'black' | 'default' | 'pulse' | 'featured' | 'transparent' | 'white';
export type Size = 'default' | 'extra-small' | 'small' | 'medium' | 'large' | 'full-width';
export type Type = 'button' | 'submit' | 'reset';

@Component({
  selector: 'lib-button-form-field',
  templateUrl: './button-form-field.component.html',
  styleUrls: ['./button-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class ButtonFormFieldComponent {
  @Input() disabled = false;
  @Input() theme: Theme[] = ['default'];
  @Input() size: Size = 'default';
  @Input() type: Type = 'submit';
  @Input() loading = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter<MouseEvent>();

  get cssClass() {
    return `button ${this.theme?.join(' ')} ${this.size} ${this.loading ? 'loading' : ''} ${
      this.disabled ? 'disabled' : ''
    }`;
  }

  onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disabled) this.click.emit();
  }
}
