import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  output,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { BrandService } from 'brand';

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(
    private parentControl: AbstractControl,
    private errors: ValidationErrors
  ) {}

  isErrorState(): boolean {
    const hasErrors = !!this.errors;
    const parentHasErrors = this.parentControl.invalid || hasErrors;
    return this.parentControl && this.parentControl.touched && parentHasErrors;
  }
}

let nextId = 0;

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    '[class.has-icon-suffix]': 'clearInputSuffix()',
  },
})
export class FormFieldBaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  protected _changeDetector = inject(ChangeDetectorRef);
  ngControl = inject(NgControl, { optional: true, self: true });
  protected brandService = inject(BrandService);
  @Input() control = new FormControl(this.ngControl?.value);
  @HostBinding('class.custom-form-field') cssClass = true;
  @HostBinding('class.has-icon-suffix') editIcon: string;
  @Input() @HostBinding('attr.id') id = `form-field-${nextId++}`;
  @Input() class = '';
  @Input() label = '';
  @Input() name = '';
  @Input() @HostBinding('class.has-icon') icon: string;
  @Input() @HostBinding('class.has-icon-suffix') iconSuffix: string;
  @Input() @HostBinding('class.has-icon-suffix') checkmarkIcon: string;
  @Input() placeholder = '';
  @Input() size = 20;
  @Input() type = 'text';
  @Input() labelSuffix: string;
  showErrors = input(true);
  subscriptSizing = input<'fixed' | 'dynamic'>();
  clearInputSuffix = input<'always' | 'never' | 'invalid'>();
  actionSuffix = input<boolean>(false);
  @Output() onInput = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  public actionButtonClick = output<void>();

  @Input()
  get value() {
    return this.control.value;
  }

  set value(value: string) {
    this.control.patchValue(value);
    this._changeDetector.markForCheck();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    if (this._disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    this._changeDetector.markForCheck();
  }

  private _disabled = false;
  @Input() showEditIcon = false;
  private _destroyed = new Subject<void>();

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }

  private _readonly = false;
  private platformId = inject(PLATFORM_ID);

  get parentControl() {
    return this.ngControl?.control;
  }

  get showActionButton() {
    return this.actionSuffix() && !this.ngControl.invalid;
  }

  get showClearInput() {
    return (
      this.ngControl.enabled &&
      this.ngControl.value &&
      (this.clearInputSuffix() === 'always' ||
        (this.clearInputSuffix() === 'invalid' && this.ngControl.invalid && this.ngControl.touched))
    );
  }

  constructor() {
    this.ngControl && (this.ngControl.valueAccessor = this);
  }

  ngOnInit() {
    if (this.parentControl) {
      this.checkmarkIcon = this.checkmarkIcon || `check-mark-${this.brandService.brand}`;
      this.editIcon = 'pencil';

      this.control.setValidators(this.parentControl.validator ? this.parentControl.validator : null);
      this.control.updateValueAndValidity();

      this.parentControl.statusChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
        this._changeDetector.markForCheck();
      });
    }
  }

  get controlErrorMessage() {
    if (this.parentControl?.errors) {
      return Object.keys(this.parentControl.errors)[0];
    }
  }

  get controlName(): string | null {
    const formGroup = this.parentControl.parent.controls as {
      [key: string]: AbstractControl;
    };
    return Object.keys(formGroup).find(name => this.parentControl === formGroup[name]) || null;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  onChange: (value: unknown) => void = () => undefined;
  onTouched = (): void => undefined;

  _onInput(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.onChange(value);
    this.onInput.emit(value);
  }

  _onBlur() {
    if (!this.disabled) {
      this.onTouched();
      this.onBlur.emit();
      this._changeDetector.markForCheck();
    }
  }

  _onFocus() {
    if (!this.disabled) {
      this.onFocus.emit();
      this._changeDetector.markForCheck();
    }
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(onChange: () => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  errorMatcher() {
    return this.parentControl && new CustomFieldErrorMatcher(this.parentControl, this.parentControl.errors);
  }

  get desktopView(): boolean {
    return isPlatformBrowser(this.platformId) ? window.innerWidth > 1023 : true;
  }

  onClearInput() {
    this.ngControl.reset('');
  }

  onActionButtonClick() {
    this.actionButtonClick.emit();
  }
}
