import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { FormFieldComponent } from '../input-form-field/input-form-field.component';
import { matchValidator } from '../validators/match.validator';
import { PASSWORD_RULES, passwordStrengthValidator } from '../validators/password.validator';

@Component({
  selector: 'lib-password-strength-form-field',
  standalone: true,
  imports: [CommonModule, TranslatePipe, FormFieldComponent, ReactiveFormsModule, SvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,

  templateUrl: './password-strength-form-field.component.html',
})
export class PasswordStrengthFormFieldComponent {
  passwordForm = new FormGroup(
    {
      password: new FormControl('', [passwordStrengthValidator]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: [matchValidator('password', 'confirmPassword')] }
  );
  passwordRules = PASSWORD_RULES;
  public errorsObj = toSignal(this.passwordControl.statusChanges.pipe(map(() => this.passwordControl.errors)));
  progressValue = computed(() => {
    const errors = this.errorsObj();
    if (errors === undefined) return 0;
    const total = PASSWORD_RULES.length;
    const errorsLength = errors ? Object.keys(errors).length : 0;
    const progress = (100 * (total - errorsLength)) / total;

    return progress;
  });
  colorClass = computed(() => {
    if (this.progressValue() < 50) return 'bg-gradient-to-r from-[--color-border-negative] to-[--color-border-warning]';
    if (this.progressValue() < 67) return 'bg-gradient-to-r from-[--color-border-warning] to-[--color-border-positive]';
    return 'bg-[--color-border-positive]';
  });
  progressWidthStyle = computed(() => `width: ${this.progressValue()}%;`);
  strengthMessage = computed(() => {
    if (this.progressValue() === 0) return 'strength_requirements';
    if (this.progressValue() < 50) return 'strength_weak';
    if (this.progressValue() < 100) return 'strength_fair';
    return 'strength_strong';
  });
  disabled = input<boolean>(false);
  passwordValue = output<string>();

  constructor() {
    effect(() => {
      if (this.errorsObj() === null) {
        this.confirmPasswordControl.markAsTouched();
        this.confirmPasswordControl.updateValueAndValidity();
      }
    });
    this.passwordForm.statusChanges.subscribe(status => {
      if (status == 'VALID') this.passwordValue.emit(this.passwordControl.value);
      else this.passwordValue.emit(null);
    });
  }

  public get passwordControl() {
    return this.passwordForm?.get('password');
  }

  public get confirmPasswordControl() {
    return this.passwordForm?.get('confirmPassword');
  }
}
