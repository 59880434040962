import { AfterViewInit, Directive, ElementRef, inject, Input } from '@angular/core';

@Directive({
  selector: '[libAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  private elementRef = inject(ElementRef);
  @Input('libAutofocus') autoFocus: boolean;

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.elementRef.nativeElement.focus();
    }
  }
}
