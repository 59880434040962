import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isAfter, isBefore, isFuture, isValid, isWeekend, parse } from 'date-fns';
import { DATE_FORMAT } from 'utils';

export const dateValidator =
  (options?: {
    futureDatesNotAllowed?: boolean;
    minDate?: Date;
    maxDate?: Date;
    weekendsNotAllowed?: boolean;
  }): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const valueAsDate = isValid(control?.value) ? control.value : parse(control.value, DATE_FORMAT, new Date());

    if (!isValid(valueAsDate)) {
      return { notValidDate: true };
    }

    if (options?.futureDatesNotAllowed && isFuture(valueAsDate)) {
      return { futureDatesNotAllowed: true };
    }

    if (options?.minDate && isBefore(valueAsDate, options?.minDate)) {
      return { dateBeforeMinDate: true };
    }

    if (options?.maxDate && isAfter(valueAsDate, options?.maxDate)) {
      return { dateAfterMaxDate: true };
    }

    if (options?.weekendsNotAllowed && isWeekend(valueAsDate)) {
      return { weekendsNotAllowed: true };
    }

    return null;
  };
