<div class="flex flex-col" [formGroup]="passwordForm">
  <lib-input-form-field
    class="w-full"
    [theme]="'primary'"
    [label]="['password', 'password'] | translate"
    [showHidePassword]="true"
    type="password"
    id="password"
    autocomplete="off"
    [placeholder]="['password', 'password_placeholder'] | translate"
    formControlName="password"
    [showErrors]="false"
    [disabled]="disabled()"
    [autoFocus]="true"
  >
  </lib-input-form-field>
  <lib-input-form-field
    class="w-full"
    [theme]="'primary'"
    [label]="['password', 'confirm_password'] | translate"
    [showHidePassword]="true"
    [disabled]="disabled()"
    type="password"
    id="confirm_password"
    autocomplete="off"
    [placeholder]="['password', 'confirm_password_placeholder'] | translate"
    formControlName="confirmPassword"
  >
  </lib-input-form-field>

  <h6 class="font-bold">{{ ['password', 'strength_label'] | translate }}</h6>
  <div class="w-full h-1.5 bg-[--color-background-tertiary] rounded-sm">
    <div
      class="opacity-60 h-full rounded-sm transition-all w-full"
      [ngClass]="colorClass()"
      [style]="progressWidthStyle()"
    ></div>
  </div>

  <p class="my-2">
    {{ ['password', strengthMessage()] | translate }}
  </p>

  <h6 class="font-bold mt-6 mb-2">{{ ['password', 'requirements_label'] | translate }}</h6>

  <div>
    <ul>
      @for (rule of passwordRules; track $index) {
        <li class="flex items-center leading-8">
          <div class="inline-block w-7">
            @if (errorsObj() !== undefined && !errorsObj()?.[rule.key]) {
              <span class="w-4 h-4 rounded-full bg-[--color-primary-opacity-light] flex items-center justify-center">
                <lib-svg [svg]="'check'" class="!fill-[--color-primary] w-2.5 [&_svg]:w-full"></lib-svg
              ></span>
            }
          </div>

          <span>{{ ['password', rule.key] | translate }}</span>
        </li>
      }
    </ul>
  </div>
</div>
