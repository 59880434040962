<mat-form-field appearance="outline" [class]="class" [subscriptSizing]="desktopView ? 'fixed' : 'dynamic'">
  <mat-label *ngIf="label">{{ label }}</mat-label>

  <lib-svg
    *ngIf="icon"
    [svg]="icon"
    matIconPrefix
    class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
  ></lib-svg>

  <input
    matInput
    [matAutocomplete]="autocomplete"
    [errorStateMatcher]="errorMatcher()"
    class="text-dark"
    [formControl]="control"
    [type]="type"
    [name]="name"
    [placeholder]="placeholder"
    [size]="size"
    [readonly]="readonly"
    (input)="_onInput($event)"
    (blur)="_onBlur()"
    (focus)="_onFocus()"
    data-testid="new-input-data"
  />
  <span *ngIf="labelSuffix" aria-atomic="true" matTextSuffix aria-live="polite">{{ labelSuffix }}</span>

  <lib-svg
    *ngIf="iconSuffix"
    [svg]="iconSuffix"
    matIconSuffix
    class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
  ></lib-svg>

  <img *ngIf="loading" matIconSuffix src="/resources/small-spinner.gif" class="h-5 ml-2 p-3 block box-content" />

  <lib-svg
    *ngIf="!loading && ngControl.valid && ngControl.dirty && !ngControl.disabled && ngControl.value && selected"
    matIconSuffix
    [svg]="checkmarkIcon"
    class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto p-3 block box-content"
  ></lib-svg>

  <mat-autocomplete
    autoActiveFirstOption
    #autocomplete="matAutocomplete"
    (optionSelected)="onOptionSelected($event.option.value)"
    (opened)="opened.emit()"
    (closed)="closed.emit()"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="showErrors() && controlErrorMessage && parentControl?.invalid">
    {{ ['form_errors', controlName, controlErrorMessage] | translate }}
  </mat-error>
</mat-form-field>
