import { animate, style, transition, trigger } from '@angular/animations';
export const toggledContentAnimation = trigger('toggledContent', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),

    animate('.2s cubic-bezier(0.4,0.0,0.2,1)', style({ height: '*', opacity: '*' })),
  ]),

  transition(':leave', [
    style({ height: '*', opacity: '*' }),

    animate('.2s cubic-bezier(0.4,0.0,0.2,1)', style({ height: 0, opacity: 0 })),
  ]),
]);
