import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { format, isDate } from 'date-fns';
import { de, enUS, frCH, it } from 'date-fns/locale';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { LanguageService } from 'language';
import { TranslatePipe } from 'translate';
import { CUSTOM_DATE_FORMATS, DATE_FORMAT } from 'utils';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'lib-date-picker-form-field',
  templateUrl: './date-picker-form-field.component.html',
  styleUrls: ['./date-picker-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgTemplateOutlet,
    NgxMaskDirective,
    NgxMaskPipe,
    NgClass,
    SvgComponent,
    TranslatePipe,
  ],
  providers: [
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_LOCALE,
      useValue: de,
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    provideNgxMask(),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerFormFieldComponent extends FormFieldBaseComponent implements OnInit {
  protected _changeDetector = inject(ChangeDetectorRef);
  ngControl = inject(NgControl, { optional: true, self: true });
  private _dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private langService = inject(LanguageService);
  protected brandService = inject(BrandService);
  @ViewChild('maskedInput') maskedInput: ElementRef;
  @Output() onDateChange = new EventEmitter<string>();
  today = new Date();
  @Input() maxDate = this.today;
  @Input() minDate: Date;
  @Input() startCalendarDateAt = this.today;
  @Input() weekendsAllowed = true;

  get currentLocale() {
    switch (this.langService.current) {
      case 'en':
        return enUS;
      case 'fr':
        return frCH;
      case 'it':
        return it;
      default:
        return de;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this._dateAdapter.setLocale(this.currentLocale);
  }

  dateChange($evt: MatDatepickerInputEvent<Date> | Event) {
    this.showEditIcon = false;
    this._onDateChange($evt as MatDatepickerInputEvent<Date>);
  }

  _onDateChange($evt: MatDatepickerInputEvent<Date>) {
    if (!isDate($evt.value)) {
      return;
    }

    const value = format($evt.value as Date, DATE_FORMAT);

    this.onChange(value);
    this.onDateChange.emit(value);
  }

  dispatch(input: HTMLInputElement, event: string, evt?: Event) {
    input.dispatchEvent(new Event(event));
    if (event === 'input') {
      this._onInput(evt);
      this.dateChange(evt);
    }
    if (event === 'blur') {
      this._onBlur();
    }
    if (event === 'focus') {
      this._onFocus();
    }
  }

  onEditIconClick() {
    this.maskedInput.nativeElement.focus();
  }

  weekendsFilter = (d: Date | null): boolean => {
    if (this.weekendsAllowed) return true;

    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };
}
