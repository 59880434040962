<mat-form-field appearance="outline" [class]="class" [subscriptSizing]="desktopView ? 'fixed' : 'dynamic'">
  <mat-label *ngIf="label">{{ label }}</mat-label>

  <lib-svg
    *ngIf="icon"
    [svg]="icon"
    matIconPrefix
    class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
  ></lib-svg>
  <mat-select
    [value]="initialValue"
    (selectionChange)="selectionChange($event)"
    disableOptionCentering
    panelClass="custom-dropdown-class"
    [formControl]="control"
    [errorStateMatcher]="errorMatcher()"
    [placeholder]="placeholder"
    (blur)="_onBlur()"
    (focus)="_onFocus()"
  >
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>

  <lib-svg
    *ngIf="iconSuffix"
    [svg]="iconSuffix"
    matIconSuffix
    class="w-6 h-6 [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] [&_svg]:w-auto [&_svg]:h-auto p-3 block box-content"
  ></lib-svg>

  <lib-svg
    *ngIf="ngControl.valid && ngControl.dirty && !ngControl.disabled"
    matIconSuffix
    [svg]="checkmarkIcon"
    class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto p-3 block box-content"
  ></lib-svg>
  <mat-error
    *ngIf="
      showErrors() &&
      controlErrorMessage &&
      ((control.errors && control.errors['required'] && !control.value) || (control.invalid && control.value))
    "
  >
    {{ ['form_errors', controlName, controlErrorMessage] | translate }}
  </mat-error>
</mat-form-field>
